/**
 * slick slider and slider count Function
 */
function slickSliderCarousel() {
    var $sliderCount = $('.slider-count-rv');
    $sliderCount.addClass('active');
    $('.category-item-images').on('init reInit afterChange', function (event, slick, currentSlide) {
        var i = (currentSlide || 0) + 1;
        $sliderCount.text(i + '/' + slick.slideCount);
    });

    $('.category-item-images').slick({
        autoplay: false,
        infinite: false,
        speed: 1000,
        autoplaySpeed: 1000,
        fade: true,
        cssEase: 'ease-in-out',
        arrows: true,
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">Previous</button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">Next</button>'
    });
}

/**
 * Constract the image tag.
 * @param {number} $carouselImages contains all the required images.
 */
function collectingCarouselImages($carouselImages) {
    if ($('.category-item-images').hasClass('slick-slider')) {
        $('.category-item-images').slick('unslick');
        $('.category-item-images').html('');
        $('.slider-count-rv').removeClass('active');
    }

    if ($carouselImages.length > 0) {
        $.each($carouselImages, function (index, value) {
            var imgtag = $('<img class="carouel-images img-responsive">');
            imgtag.attr('src', value);
            $('.category-item-images').append(imgtag);
        });
        slickSliderCarousel();
    }
}

/**
 * getting all the images.
 * @param {number} $cat is the category.
 */
function loadAllCarouselImages($cat) {
    var $carouselImages = $cat.find('.category-item:first-child').data('category-info').carousalImageUrls;
    collectingCarouselImages($carouselImages);
}

/**
 * Function to constract subheading and discription html.
 * @param {number} heading is the category heading.
 * @param {number} desc is the category description.
 * @param {number} url is the cta button url.
 * @param {number} name is the cta button text.
 */
function subcategoryHtml(heading, desc, url, name) {
    var html = '';
    var subHeading = '<h3 class="text-h4">' + heading + '</h3>';
    var description = '<p class="text-b1">' + desc + '</p>';
    var buttonUrl = '<a class="btn btn-primary" href="' + url + '">' + name + '</a>';
    html = subHeading + description + buttonUrl;
    $('.rv-subcategory').html('');
    $('.rv-subcategory').append(html);
}

/**
 * Function to check shop or rent button prefix.
 * @param {number} $btn is active category.
 * @returns {string} string buttton prefix name.
 */
function getButtonPrefix($btn) {
    var btnPrefixName = '';
    if (($btn.data('category') === 'cat-1') || $('.Find-perfect-RV').hasClass('buy-clp-page')) {
        btnPrefixName = $('.Find-perfect-RV').attr('data-ctaprefix1');
    } else if ($('.Find-perfect-RV').hasClass('travelhome-buy-clp-page')) {
        btnPrefixName = 'See';
    } else {
        btnPrefixName = $('.Find-perfect-RV').attr('data-ctaprefix2');
    }
    return btnPrefixName;
}

/**
 * getting all the sub category data.
 * @param {number} $btn is the active category.
 * @param {number} $cat is the category.
 */
function loadSubCategoryData($btn, $cat) {
    var subHeading = $cat.find('.category-item:first-child').data('category-info').name;
    var description = $cat.find('.category-item:first-child').data('category-info').description;
    var url = $cat.find('.category-item:first-child').data('category-info').url;
    var btnPrefix = getButtonPrefix($btn);
    if($('.Find-perfect-RV').hasClass('travelhome-buy-clp-page')) {
        var name = btnPrefix + ' ' + $cat.find('.category-item:first-child').data('category-info').categoryShortName + ' RVs';
    } else {
        var name = btnPrefix + ' ' + $cat.find('.category-item:first-child').data('category-info').categoryShortName;
    }
    subcategoryHtml(subHeading, description, url, name);
}

/**
 * Function to ininitilize the slider buttons.
 */
function btnSliderInit() {
    var btnWidth = $('.btn-1').innerWidth();
    $('.btn-slider').css('width', btnWidth);
    $('.btn-slider').css('left', '-2px');
}

/**
 * Function to ininitilize category.
 * @param {number} $btn button one which category button selected.
 * @param {number} $cat category one which loads on page load.
 */
function onPageLoadRV($btn, $cat) {
    $('.Find-perfect-RV').find($cat).addClass('active');


    setTimeout(() => {
        $('.slider-RV-buttons').find($btn).addClass('active');
    }, 500);
    $('.Find-perfect-RV').find($cat).find('.category-item .rv-cat-image-container').removeClass('active');
    $('.Find-perfect-RV').find($cat).find('.category-item:first-child .rv-cat-image-container').addClass('active');

    const navigate = $('.Find-perfect-RV').data('navigate');
    if(navigate) {
        return;
    }
    loadSubCategoryData($btn, $cat);
    loadAllCarouselImages($cat);
}

/**
 * Function to load all the sub category carousel images and data.
 * @param {number} $btn category one which slected.
 * @param {number} $this category one which slected.
 */
function loadAllSubCategoryCarouselImages($btn, $this) {
    var $carouselImages = $this.closest('.category-item').data('category-info').carousalImageUrls;
    collectingCarouselImages($carouselImages);
    var subHeading = $this.closest('.category-item').data('category-info').name;
    var description = $this.closest('.category-item').data('category-info').description;
    var url = $this.closest('.category-item').data('category-info').url;
    var btnPrefix = getButtonPrefix($btn);
    if($('.Find-perfect-RV').hasClass('travelhome-buy-clp-page')) {
        var name = btnPrefix + ' ' + $this.closest('.category-item').data('category-info').categoryShortName + ' RVs';
    } else {
        var name = btnPrefix + ' ' + $this.closest('.category-item').data('category-info').categoryShortName;
    }
    subcategoryHtml(subHeading, description, url, name);
}

$(document).ready(function () {
    var $btn = $('.btn-1');
    var $cat = $('.main-category-1');
    setTimeout(() => {
        btnSliderInit();
    }, 500);
    onPageLoadRV($btn, $cat);

    $('.slider-button').on('click', function () {
        $('.slider-button').removeClass('active');
        $('.Find-perfect-RV').find('.category-container').removeClass('active');
        if ($(this).data('category') === 'cat-1') {
            $('.Find-perfect-RV').find('.main-category-1').addClass('active');
            var $btn1 = $('.btn-1');
            var $cat1 = $('.main-category-1');
            btnSliderInit();
            onPageLoadRV($btn1, $cat1);
        } else {
            $('.Find-perfect-RV').find('.main-category-2').addClass('active');
            var $btn2 = $('.btn-2');
            var $cat2 = $('.main-category-2');
            onPageLoadRV($btn2, $cat2);
            var btnWidth = $('.btn-1').innerWidth();
            var btnWidthRent = $('.btn-2').innerWidth();
            $('.btn-slider').css('width', btnWidthRent);
            $('.btn-slider').css('left', btnWidth);
        }
    });

    const $parent = $('.Find-perfect-RV');
    const navigate = $parent.data('navigate');

    if(navigate) {
        $('.category-item').click(function() {  
            var href = $(this).find('a').attr('href');
            window.location.href = href;
        });
    } else {
        $('.rv-cat-image').on('click', function () {
            var $this = $(this);
            var btnActive = '';
            $('.rv-cat-image-container').removeClass('active');
            $(this).closest('.rv-cat-image-container').addClass('active');
            if ($('.btn-1').hasClass('active')) {
                btnActive = $('.btn-1');
            } else {
                btnActive = $('.btn-2');
            }
            loadAllSubCategoryCarouselImages(btnActive, $this);
        });
    }

    $(window).on('resize', function () {
        if ($('.btn-1').hasClass('active')) {
            var switchWidthbtn1 = $('.btn-1').innerWidth();
            $('.btn-slider').css('width', switchWidthbtn1);
        } else {
            var switchWidthbtn2 = $('.btn-2').innerWidth();
            $('.btn-slider').css('width', switchWidthbtn2);
        }
    });
});
